<template>
  <div class="contactUsRecord">
    <v-data-table
      :headers="headers"
      :items="contactUsRecord"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      { text: "Id", value: "id" }, { text: "Name", value: "ownerName" }, { text:
      "Contact", value: "contactNumber" }, { text: "Email", value: "email" }, {
      text: "Message", value: "message" }, { text: "Intention", value:
      "intention" }, { text: "Referal", value: "referal" }, { text: "Referal
      Url", value: "referalUrl" }, { text: "Created Time", value: "createTime"
      },
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.phone }}</td>
          <td>{{ row.item.email }}</td>
          <td>{{ row.item.message }}</td>
          <td>{{ row.item.intention }}</td>
          <td>{{ row.item.referral }}</td>
          <td>{{ row.item.referralUrl }}</td>
          <td>{{ row.item.createTime }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import getAxios from "../../../services/axios-get";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Contact", value: "contactNumber" },
        { text: "Email", value: "email" },
        { text: "Message", value: "message" },
        { text: "Intention", value: "intention" },
        { text: "Referal", value: "referal" },
        { text: "Referal Url", value: "referalUrl" },
        { text: "Created Time", value: "createTime" },
      ],
      contactUsRecord: [],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchContactUsRecord();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchContactUsRecord();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchContactUsRecord();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchContactUsRecord();
    },
    fetchContactUsRecord() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/actionSystem/contactUs`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            console.log(res.data.data.content);
            self.contactUsRecord = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
            self.contactUsRecord.forEach(x => {
              x.createTime = this.formatDate(x.createTime);
            });
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    formatDate(datex) {
      let date = new Date(datex);
      let formatted_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes();
      return formatted_date;
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
